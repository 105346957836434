import React from 'react';
import { Link } from 'react-router-dom';
class MakeADonation extends React.Component {
    render() {
        return (
            <section className="causes-section bg-grey bd-bottom shape circle padding" id="MakeADonation">
                <div className="container">
                    <div className="section-heading text-center mb-40" id="MakeADonation">
                        <h2>Make a Donation</h2>
                        <span className="heading-border"></span>
                        <p>We are collecting monetary donations as well as items needed by various organizations.</p>
                    </div>
                    <div className="causes-wrap row">
                        <div className="col-md-2 xs-padding"></div>
                        <div className="col-md-4 xs-padding">
                            <div className="causes-content">
                                <div className="causes-thumb">
                                    <img src="assets/img/causes-1.jpg" alt="causes" />
                                    <Link to="/donateother" className="donate-btn">Donate Now<i className="ti-plus"></i></Link>
                                    {/* <a href="https://charity.gofundme.com/dreamthings" className="donate-btn">Donate Now<i className="ti-plus"></i></a> */}
                                    {/* <div className="progress">
                                            <div className="progress-bar" role="progressbar"><span className="wow cssanimation fadeInLeft">25%</span></div>
                                        </div> */}
                                </div>
                                <div className="causes-details">
                                    <h3>Items for We Care Charity</h3>
                                    <p>Donate items to help out We Care Charity.</p>
                                    {/* <div className="donation-box"></div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 xs-padding">
                            <div className="causes-content">
                                <div className="causes-thumb">
                                    <img src="assets/img/causes-2.jpg" alt="causes" />
                                    <Link to="/donateother" className="donate-btn">Donate Now<i className="ti-plus"></i></Link>
                                    {/* <a href="#" className="donate-btn">Donate Now<i className="ti-plus"></i></a> */}
                                    {/* <div className="progress">
                                            <div className="progress-bar" role="progressbar"><span className="wow cssanimation fadeInLeft">45%</span></div>
                                        </div> */}
                                </div>
                                <div className="causes-details">
                                    <h3>Items for House of Mercy</h3>
                                    <p>Donate items to help provide for the homeless at House of Mercy.</p>
                                    {/* <div className="donation-box"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default MakeADonation;