import React from 'react';
import { Link } from 'react-router-dom';
import Counter from '../components/Counter';
import Mission from '../components/Mission';

class About extends React.Component {
    render() {
        return (
            <div>
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>About Us</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">About Us</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                                                
                                <div className="col-md-12 xs-padding">                                    
                                    <Link to="/donate">Donate</Link>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="about-section bg-grey bd-bottom padding">
                    <div className="container">
                        <div className="row about-wrap">
                            <div className="col-md-3 xs-padding">
                            </div>
                            <div className="col-md-6 xs-padding">
                                <div className="about-content">
                                    <h2>Who we are</h2>
                                    <p>The founder of this organization, Shipra, is a high school junior. She has been assisting local charities and homeless shelters since the start of the COVID-19 pandemic by providing food for the homeless and has additionally been doing similar volunteer work for over two years.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Counter />
                <Mission />

            </div>
        )
    }
}
export default About;