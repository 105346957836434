
import React from 'react';
import {Link} from 'react-router-dom';
import AllFeedHungryEvents from '../components/AllFeedHungryEvents';

class FeedHungry extends React.Component{
    render() {
        return(
            <div>
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Feed Hungry</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Feed Hungry</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <Link to="/donate">Donate</Link>                                        
                                    </ol>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <AllFeedHungryEvents />
                
            </div>
        )
    }
}
export default FeedHungry;