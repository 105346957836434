import React from 'react';
// import { Link } from 'react-router-dom';
class Promo extends React.Component {
    render() {
        return (
            
            <section className="promo-section bd-bottom">
                <div className="promo-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 xs-padding">
                                <div className="promo-content">
                                    <img src="assets/img/icon-1.png" alt="prmo icon" />
                                    <h3>Feed the Hungry</h3>
                                    <p>We are collecting food donations for homeless shelters and charities.</p>
                                    <a href="/#FeedTheHungry">Read More</a>                                    
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 xs-padding">
                                <div className="promo-content">
                                    <img src="assets/img/icon-2.png" alt="prmo icon" />
                                    <h3>Make a Donation</h3>
                                    <p>We are collecting monetary donations as well as items needed by various organizations.</p>                                    
                                    {/* <Link to="/#MakeADonation">Read More</Link> */}
                                    <a href="/#MakeADonation">Read More</a>
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-sm-6 xs-padding">
                                <div className="promo-content">
                                    <img src="assets/img/icon-3.png" alt="prmo icon" />
                                    <h3>Personalized Messages</h3>
                                    <p>We are creating small cards with words of kindness and sending them to the homeless at House of Mercy. If you would like, you can send in your own message and we will put it on a card!
                                        </p>
                                    <a href="/#PersonalizedMessages">Read More</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Promo;