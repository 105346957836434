import React from 'react';
import { Link } from 'react-router-dom';
class Footer extends React.Component {
    render() {
        return (
            <div>
                <section className="widget-section padding">
                    <div className="container">
                        <div className="widget-wrap row">
                            <div className="col-md-3 xs-padding">
                                <div className="widget-content">                                    
                                    <img src="assets/img/logo.png" length="204" height="50" alt="Brand"/>
                                    <p>Created our free logo at <a href="https://logomakr.com" target="_blank" rel="noopener noreferrer">logomakr.com</a> </p>
                                    <ul className="social-icon">
                                        <li><a href="https://www.facebook.com/DreamThings.org" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                                        {/* <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li> */}
                                        <li><a href="/#" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 xs-padding">  
                                <p>Site Map</p>
                                <ul className="address">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/feedhungry">Feed The Hungry</Link></li>


                                </ul>                              
                            </div>
                            <div className="col-md-3 xs-padding">  
                                <p>&nbsp;</p>
                                <ul className="address">                                    
                                    <li><Link to="/howitworks">How It Works</Link></li>
                                    <li><Link to="/donate">Donate</Link></li>
                                    <li><Link to="/donateitems">Donate Items</Link></li>
                                    <a href="https://charity.gofundme.com/dreamthings" target="_blank" rel="noopener noreferrer">Donate via GoFundMe</a>
                                    <li><Link to="/donatebycheck">Donate by Bank Transfer</Link></li>
                                    <li><Link to="/donatefood">Donate Food</Link></li>                                                                      
                                    <li><Link to="/stickers">Write a Message</Link></li>                                    
                                </ul>                              
                            </div>
                            <div className="col-md-3 xs-padding">
                                <div className="widget-content">
                                    {/* <h3></h3> */}
                                    <ul className="address">
                                        <li><i className="ti-email"></i>info@DreamThings.org</li>                                        
                                        <li><i className="ti-world"></i>www.DreamThings.org</li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                
                <footer className="footer-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 sm-padding">
                                <div className="copyright">&copy; 2020 Dream Things</div>DreamThings is fiscally sponsored by Hack+, 501(c)(3) nonprofit. EIN: 81-1543325
                            </div>                        
                        </div>
                    </div>
                </footer>
                <a data-scroll href="#header" id="scroll-to-top"><i className="arrow_up"></i></a>

                {/* <script src="assets/js/vendor/jquery-1.12.4.min.js"></script> */}
                {/* <script src="assets/js/vendor/bootstrap.min.js"></script>
                <script src="assets/js/vendor/tether.min.js"></script>
                <script src="assets/js/vendor/imagesloaded.pkgd.min.js"></script>
                <script src="assets/js/vendor/owl.carousel.min.js"></script>
                <script src="assets/js/vendor/jquery.isotope.v3.0.2.js"></script>
                <script src="assets/js/vendor/smooth-scroll.min.js"></script>
                <script src="assets/js/vendor/venobox.min.js"></script>
                <script src="assets/js/vendor/jquery.ajaxchimp.min.js"></script>
                <script src="assets/js/vendor/jquery.counterup.min.js"></script>
                <script src="assets/js/vendor/jquery.waypoints.v2.0.3.min.js"></script>
                <script src="assets/js/vendor/jquery.slicknav.min.js"></script>
                <script src="assets/js/vendor/jquery.nivo.slider.pack.js"></script>
                <script src="assets/js/vendor/letteranimation.min.js"></script>
                <script src="assets/js/vendor/wow.min.js"></script>
                <script src="assets/js/contact.js"></script>  */}
                {/* <script src="assets/js/main.js"></script> */}

            </div>
        )
    }
} 
export default Footer;