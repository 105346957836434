import React from 'react';
import { Link } from 'react-router-dom';
// import { Nav, Navbar, NavDropdown, MenuItem, Tabs, ButtonToolbar, Button, Table, ButtonGroup, Row, Col, Grid, Panel, FormGroup, FormControl } from 'react-bootstrap';

class NavTop extends React.Component {
    render() {
        return (
            <div className="bottom-header">
                <div className="container">
                    <div className="bottom-content-wrap row">                        
                        <div className="col-sm-4">
                        <div className="site-branding">
                            <Link to="/"><img src="assets/img/logo.png" length="204" height="50" alt="Brand" /></Link>
                        </div>
                    </div>
                    <div className="col-sm-8 text-right">
                        <ul id="mainmenu" className="nav navbar-nav nav-menu">
                            <li className="active"><li><Link to="/">Home</Link></li></li>
                            <li className="active"><Link to="/howitworks">How It Works</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            {/* <li><Link to="/recentstories">RecentStories</Link></li> */}
                        </ul>
                        <Link to="/donate" className="default-btn">Donate</Link>
                    </div>
                </div>
            </div>
            </div >

        )
    }
}

export default NavTop;
