import React from 'react';
import { Link } from 'react-router-dom';


const initialstate = {
    name: '',
    email: '',
    phone: '',
    perishable: '',
    note: '',
    message: '',
    isSubmitting: false,
    isError: false
}
class DonateItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialstate;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if (event.target.id === 'name') {
            this.setState({ name: event.target.value });
        } else if (event.target.id === 'email') {
            this.setState({ email: event.target.value });
        } else if (event.target.id === 'phone') {
            this.setState({ phone: event.target.value });
        } else if (event.target.id === 'perishable') {
            this.setState({ perishable: event.target.value });
        } else if (event.target.id === 'note') {
            this.setState({ note: event.target.value });
        }
    }
    handleSubmit = async e => {
        e.preventDefault();
        console.log(this.state);
        this.setState({ isSubmitting: true });
        console.log(JSON.stringify(this.state));
        var jsondata = JSON.stringify(this.state);
        console.log("json data->" + JSON.stringify(this.state));
        const res = await fetch("https://api.dreamthings.org/v1/item", {
            method: "POST",
            body: jsondata,
            headers: {
                "Content-Type": "application/json"
            }
        });
        this.setState({ isSubmitting: false });
        const data = await res.json();
        !data.hasOwnProperty("error")
            ? this.setState({ message: 'Thank you for contacting us!' })
            : this.setState({ message: 'Error processing form', isError: true });

        setTimeout(
            () =>
                this.setState({
                    isError: false,
                    message: "",
                    name: "",
                    email: "",
                    phone: "",
                    perishable: "",
                    note: ""
                }),
            1600
        );
    };
    render() {
        return (
            <div>
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Donate Items</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Donate Items</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-section padding" id="DonateItemForm">
                    <div className="container">
                        <div className="row contact-wrap">
                            <div className="col-md-12 xs-padding">
                                <div className="contact-form">
                                    <h3>Donate Items</h3>
                                    <p>Fill out this form and we will contact you to let you know the drop off location and instructions for drop off.</p>
                                    <form onSubmit={this.handleSubmit} id="ajax_form" className="form-horizontal">
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-3">
                                                <input type="text" id="name" value={this.state.name} onChange={this.handleChange} className="form-control" placeholder="Name" required />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="email" id="email" value={this.state.email} onChange={this.handleChange} className="form-control" placeholder="Email" required />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="phone" id="phone" value={this.state.phone} onChange={this.handleChange} className="form-control" placeholder="Phone"/>
                                            </div>                                            
                                        </div>
                                        {/* <div className="form-group row">
                                            <div className="col-md-12">                                           
                                                <label>Are these Parishable Items?:
                                                    <select value={this.state.perishable} className="form-control" onChange={this.handleChange}>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>                                                        
                                                    </select>
                                                </label>                                           
                                            </div>
                                        </div> */}
                                        <div className="form-group row">
                                            <div className="col-md-9">
                                                <textarea id="note" value={this.state.note} onChange={this.handleChange} cols="30" rows="5" className="form-control message" placeholder="Please list the items you are donating here" required></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <button id="submit" className="default-btn" type="submit">Submit</button>
                                            </div>
                                        </div>
                                        <div className={`message ${this.state.isError && "error"}`}>
                                            {this.state.isSubmitting ? "Submitting..." : this.state.message}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default DonateItems;