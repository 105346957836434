import React from 'react';
import TopStories from '../components/TopStories';
import { Link } from 'react-router-dom';
class ShowcaseStories extends React.Component {
    render() {
        return (
            <section className="blog-section bg-grey bd-bottom padding" id="RecentStories">
                <div className="container">
                    <div className="section-heading text-center mb-40">
                        <h2>Recent Stories</h2>
                        <span className="heading-border"></span>
                        <p>Some of our recent work!</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 xs-padding">
                            <TopStories />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="col-md-10"></div> */}
                            {/* <div className="col-md-4"></div> */}
                            <div className="col-md-12 align-center">
                                <Link to="/recentstories" className="default-btn">More stories</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section >
        )
    }
}

export default ShowcaseStories;