import React from 'react';
import { Link } from 'react-router-dom';

class Donate extends React.Component {

    render() {
        return (
            <div>                                
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Donate</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Donate</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="promo-section bd-bottom">
                    <div className="promo-wrap">
                    <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-1.png" alt="prmo icon"/> */}
                                        <h3>Donate via GiveLively</h3>
                                        <p>Donate through a GiveLively page</p>
                                        {/* <Link to="/donate" className="default-btn">Donate</Link> */}
                                        <a href="https://hack.ms/dreamthings" target="_blank" rel="noopener noreferrer" className="default-btn">Donate Now</a>
                                    </div>  
                                </div>
                                <div className="col-md-3 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-2.png" alt="prmo icon"/> */}
                                        <h3>Donate by Bank Transfer</h3>
                                        <p>Donate by check or bank transfer</p>
                                        <Link to="/donatebycheck" className="default-btn">Donate Now</Link>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-3.png" alt="prmo icon"/> */}
                                        <h3>Donate Items</h3>
                                        <p>Fill out a form to donate items</p>
                                        <Link to="/donateitems" className="default-btn">Donate Items</Link>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-3.png" alt="prmo icon"/> */}
                                        <h3>Donate Food</h3>
                                        <p>Fill out a form to donate food items</p>
                                        <Link to="/donatefood" className="default-btn">Donate Food</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                
                {/* <section className="blog-section bg-grey bd-bottom padding" id="RecentStories">
                    <div className="container">
                        <div className="section-heading text-center mb-40">
                            <h2>Recent Stories</h2>
                            <span className="heading-border"></span>
                            <p>Some of our recent work!</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 xs-padding">
                                <div className="blog-items grid-list row">
                                    <div className="col-md-2 padding-15"></div>
                                    <div className="col-md-4 padding-15">
                                        <div className="blog-post">
                                            <img src="assets/img/home-blog-1.jpg" alt="blog post"/>
                                            <div className="blog-content">
                                                <span className="date"><i className="fa fa-clock-o"></i> August 28, 2020</span>
                                                <h3><a href="#">Water Donations</a></h3>
                                                <p>We recently had 25 cases of water donated to House of Mercy! Due to the current pandemic and the hot weather, House of Mercy had run short of some essential items for the homeless, most importantly water.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 padding-15">
                                        <div className="blog-post">
                                            <img src="assets/img/home-blog-2.jpg" alt="blog post"/>
                                            <div className="blog-content">
                                                <span className="date"><i className="fa fa-clock-o"></i> August 28, 2020</span>
                                                <h3><a href="#">Meal Bags</a></h3>
                                                <p>Since quarantine has been implemented, one way we have been helping out shelters with food donations is by creating meal bags. We donated them weekly!</p>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        )
    }
}

export default Donate;