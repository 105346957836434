import React from 'react';
// import { Link } from 'react-router-dom';
import NavTop from './NavTop';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };
class Header extends React.Component {
    
    render() {
        return (
            <div>
                <header id="header" className="header-section">
                    <div className="top-header">
                        <div className="container">
                            <div className="top-content-wrap row">
                                <div className="col-sm-8">
                                    <ul className="left-info">
                                        <li><Mailto email="info@DreamThings.org" subject="Hello DreamThings Team" body="Hello DreamThings Team">
                                        info@DreamThings.org</Mailto></li>
                                    </ul>
                                </div>
                                <div className="col-sm-4 d-none d-md-block">
                                    <ul className="right-info">
                                        <li><a href="https://www.facebook.com/DreamThings.org" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                                        {/* <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li> */}
                                        {/* <li><a href="/#" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <NavTop />

                </header>
            </div>
        )
    }
}

export default Header;
