import React from 'react';
import { Link } from 'react-router-dom';


const initialstate = {
    amount: '',
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    zip: '',
    note: '',
    message: '',
    isSubmitting: false,
    isError: false
}
class DonateByCheck extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialstate;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        if (event.target.id === 'name') {
            this.setState({ name: event.target.value });
        } else if (event.target.id === 'email') {
            this.setState({ email: event.target.value });
        } else if (event.target.id === 'phone') {
            this.setState({ phone: event.target.value }); 
        } else if (event.target.id === 'note') {
            this.setState({ note: event.target.value });
        } else if (event.target.id === 'amount') {
            this.setState({ amount: event.target.value });
        } else if (event.target.id === 'address1') {
            this.setState({ address1: event.target.value });
        } else if (event.target.id === 'address2') {
            this.setState({ address2: event.target.value });
        } else if (event.target.id === 'city') {
            this.setState({ city: event.target.value });
        } else if (event.target.id === 'state') {
            this.setState({ state: event.target.value });
        } else if (event.target.id === 'zip') {
            this.setState({ zip: event.target.value });
        }
    }
    handleSubmit = async e => {
        e.preventDefault();
        console.log(this.state);
        this.setState({ isSubmitting: true });
        console.log(JSON.stringify(this.state));
        var jsondata = JSON.stringify(this.state);
        console.log("json data->" + JSON.stringify(this.state));
        const res = await fetch("https://api.dreamthings.org/v1/invoice", {
            method: "POST",
            body: jsondata,
            headers: {
                "Content-Type": "application/json"
            }
        });
        this.setState({ isSubmitting: false });
        const data = await res.json();
        !data.hasOwnProperty("error")
            ? this.setState({ message: 'Thank you for contacting us!' })
            : this.setState({ message: 'Error processing form', isError: true });

        setTimeout(
            () =>
                this.setState({
                    isError: false,
                    message: "",
                    name: "",
                    email: "",
                    phone: "",
                    amount: "",
                    address1: "",
                    address2: "",
                    state: "",
                    city: "",
                    zip: "",
                    note: ""
                }),
            1600
        );
    };
    render() {
        return (
            <div>                                
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Donate by Bank Transfer</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Donate by Bank Transfer</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-section padding" id="DonateByCheckForm"> 
                    <div className="container">
                        <div className="row contact-wrap">
                            <div className="col-md-12 xs-padding">
                                <div className="contact-form" >
                                    <h3>Donate by Bank Transfer</h3>
                                    <p>Fill out the form below and we will email you with a link so that you can pay by check or other electronic transfer.</p>                                    
                                    <form onSubmit={this.handleSubmit} id="ajax_form" className="form-horizontal">
                                        <div className="form-group row">
                                            <div className="col-md-5">
                                            <input type="number" id="amount" pattern="[0-9]*" value={this.state.amount} className="form-control" placeholder="Donation amount" onChange={this.handleChange} required/>
                                            {/* <select value={this.state.value} onChange={this.handleChange} className="form-control" placeholder="Please enter amount you would like to donate" required >
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select> */}
                                            </div>
                                        </div>
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-3">
                                                <input type="text" id="name" value={this.state.name} onChange={this.handleChange} className="form-control" placeholder="Full name" required />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="email" id="email" value={this.state.email} onChange={this.handleChange} className="form-control" placeholder="Email" required />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="phone" id="phone" value={this.state.phone} onChange={this.handleChange} className="form-control" placeholder="Phone" required />
                                            </div>                                            
                                        </div>
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-9">
                                                <input type="text" id="address1" value={this.state.address1} onChange={this.handleChange} className="form-control" placeholder="Address Line 1 " required />
                                            </div>                                         
                                        </div>          
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-9">
                                                <input type="text" id="address2" value={this.state.address2} onChange={this.handleChange} className="form-control" placeholder="Address Line 2 " />
                                            </div>                                         
                                        </div>        
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-3">
                                                <input type="text" id="city" value={this.state.city} onChange={this.handleChange} className="form-control" placeholder="City " required />
                                            </div> 
                                            <div className="col-sm-3">
                                                <input type="text" id="state" value={this.state.state} onChange={this.handleChange} className="form-control" placeholder="State " required />
                                            </div> 
                                            <div className="col-sm-3">
                                                <input type="text" id="zip" value={this.state.zip} onChange={this.handleChange} className="form-control" placeholder="Zip " required />
                                            </div>                                         
                                        </div>               
                                        <div className="form-group row">
                                            <div className="col-md-9">
                                                <textarea id="note" value={this.state.note} onChange={this.handleChange} cols="30" rows="5" className="form-control message" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <button id="submit" className="default-btn" type="submit">Submit</button>
                                            </div>
                                        </div>
                                        <div className={`message ${this.state.isError && "error"}`}>
                                            {this.state.isSubmitting ? "Submitting..." : this.state.message}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default DonateByCheck;