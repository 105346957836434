import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Howitworks from './pages/Howitworks';
import Donate from './pages/Donate';
import DonateByCheck from './pages/DonateByCheck';
import DonateItems from './pages/DonateItems';
import DonateOther from './pages/DonateOther';
import Stickers from './pages/Stickers';
import DonateFood from './pages/DonateFood';
import RecentStories from './pages/RecentStories';
import FeedHungry from './pages/FeedHungry';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';

const history = createBrowserHistory();
const trackingId = "UA-178431997-1"; 
ReactGA.initialize(trackingId);

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <div>
      <Router history={history}>        
        <Header/> 
        
        <Switch>
        <ScrollToTop path="/">
          <Route exact path='/' component={Home} />   
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/howitworks' component={Howitworks} />
          <Route path='/donate' component={Donate} /> 
          <Route path='/donateother' component={DonateOther} /> 
          <Route path='/donateitems' component={DonateItems} />
          <Route path='/donatebycheck' component={DonateByCheck} />
          <Route path='/stickers' component={Stickers} />
          <Route path='/donatefood' component={DonateFood} />
          <Route path='/recentstories' component={RecentStories} />
          <Route path='/feedhungry' component={FeedHungry} />
          </ScrollToTop>
        </Switch>
        
        <Footer/>
      </Router>
      
    </div>
  );
}


export default App;
