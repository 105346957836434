import React from 'react';
class Counter extends React.Component {
    render() {
        return (
            <section id="counter" className="counter-section">
                <div className="container">
                    <ul className="row counters">                        
                        <li className="col-md-4 col-sm-6 sm-padding">
                            <div className="counter-content">
                                <i className="ti-money"></i>
                                <h3 className="counter">$2,554</h3>
                                <h4 className="text-white">Money Raised</h4>
                            </div>
                        </li>
                        <li className="col-md-4 col-sm-6 sm-padding">
                            <div className="counter-content">
                                <i className="ti-face-smile"></i>
                                <h3 className="counter">5,027</h3>
                                <h4 className="text-white">Meals Donated</h4>
                            </div>
                        </li>
                        <li className="col-md-4 col-sm-6 sm-padding">
                            <div className="counter-content">
                                <i className="ti-comments"></i>
                                <h3 className="counter">6,804</h3>
                                <h4 className="text-white">Items Donated</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Counter;