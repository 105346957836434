import React from 'react';

class ShowcaseStories extends React.Component {
    render() {
        return (
            <div className="blog-items grid-list row">
                <div className="col-md-2 padding-15"></div>
                <div className="col-md-4 padding-15">
                    <div className="blog-post">
                        <img src="assets/img/wecarecharity-10-30-2022.png" alt="blog post" />
                        <div className="blog-content">
                            <span className="date"><i className="fa fa-clock-o"></i> Oct 30th, 2022</span>
                            <h3>Served 200 Meal Bags</h3>
                            <p>Served 200 Meal Bags to WeCareCharity on <b>Oct 30, 2022!</b></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 padding-15">
                    <div className="blog-post">
                    <img src="assets/img/sommervillecoalitionhomeless-11-16-2022.png" alt="blog post" />
                        <div className="blog-content">
                            <span className="date"><i className="fa fa-clock-o"></i> Oct 16th, 2022</span>
                            <h3>Served Hot Meal @ Sommerville Coalition homeless shelter</h3>
                            <p>Served hot meal to Sommerville Coalition homeless shelter on <b>Oct 16, 2022!</b></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShowcaseStories;