import React from 'react';
import { Link } from 'react-router-dom';
class MakeADonation extends React.Component {
    render() {
        return (
            <section className="about-section bd-bottom shape circle padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 xs-padding">
                            {/* <div className="profile-wrap">
                                    <h3>Shipra Kalve<span>Founder of Dream Things</span></h3>
                                </div> */}
                        </div>
                        <div className="col-md-8 xs-padding">
                            <div className="about-wrap row">
                                <div className="col-md-6 xs-padding">
                                    <h3>Our Mission</h3>
                                    <p>Provide assistance to those in need!</p>
                                    <Link to="/howitworks" className="default-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default MakeADonation;