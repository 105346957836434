import React from 'react';
import TopStories from '../components/TopStories';
class RecentStories extends React.Component {
    render() {
        return (
            <section className="blog-section bg-grey bd-bottom padding" id="RecentStories">
                <div className="container">
                    <div className="section-heading text-center mb-40">
                        <h2>Recent Stories</h2>
                        <span className="heading-border"></span>
                        <p>Some of our recent work!</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 xs-padding">

                            <TopStories />
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/wecarecharity-11-09-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Oct 09, 2022</span>
                                            <h3>Food and Item Donations @ We Care Charity</h3>
                                            <p>We donated pasta, drinks, juices, and other food items to We Care Charity on <b>Oct 09, 2022!</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/hom-08-27-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Aug 27th, 2022</span>
                                            <h3>Food and Item Donations @ House of Mercy</h3>
                                            <p>We donated 20+ of cases of water bottles to House of Mercy on <b>August 28, 2022!</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/wcc-05-01-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> May 1st, 2022</span>
                                            <h3>Food and Item Donations @ We Care Charity</h3>
                                            <p>We donated 200+  pasta, beans, milk, juices, and other food items to We Care Charity on <b>May 01, 2022!</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/hom-03-06-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Mar 6th, 2022</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated 100+ boxes of pastries, cakes, cookies to House of Mercy on <b>March 06, 2022!.</b> Thanks to Starbucks @ Putterham square, Brookline, for the generous donation of pastries, cakes, cookies!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/schc-01-23-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Jan 23rd, 2022</span>
                                            <h3>Served Hot Meal @ Sommerville Coalition homeless shelter</h3>
                                            <p>We donated hot meal to Sommerville Coalition homeless shelter on Jan 23rd, March 13th, and August 28, 2022!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/wecarecharity-01-23-2022.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Jan 23rd, 2022</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated 60+ wholesale boxes of coffee, muffins, pasta, beverages and other food items on <b>Jan 23rd, 2022!</b> Thanks to Starbucks @ Putterham square, Brookline, for the generous donation of Coffee and Mugs!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/houseofmercy-12-30-2021.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> Dec 30th, 2021</span>
                                            <h3>Served Lunch @ House of Mercy</h3>
                                            <p>Served hot lunch at House of Mercy on December 30th, 2021!!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/houseofmercy-08-21-2021.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> August 21st, 2021</span>
                                            <h3>Water and Clothing Donations</h3>
                                            <p>We donated 20 cases of water and clothing items on August 21st, 2021!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/houseofmercy-07-08-2021.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> July 8th, 2021</span>
                                            <h3>Water and Juice Donations</h3>
                                            <p>We donated 25 cases of water and Juice cases on July 8th, 2021!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/sommervillecoalitionhomeless-04-04-and-06-05.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> April 06, 2021 and June 5, 2021</span>
                                            <h3>Food Donations</h3>
                                            <p>We donated hot meal to Sommerville Coalition homeless shelter on April 06, and June 5th 2021!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/houseofmercy-02-06-2021.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> February 06, 2021</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated 200+ cereal and juices to House of Mercy on February 06, 2021!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/wecarecharity-01-03-2021.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> January 03, 2021</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated canned food items, pasta, rice, beans, sauce, soups and other items to WeCareCharity on January 03, 2021!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/event-dominic-fooddrive-12-26-2020.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> December 26, 2020</span>
                                            <h3>Dominican Republic Mission 2021 Food Drive</h3>
                                            <p>We donated canned food items, oil, sugar, pasta, rice, beans, sauce, and other items to House Of Mercy's Dominican Republic Mission 2021 Food Drive on December 26, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/event-winter-coatdrive-wecarecharity-12-26-2020.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> December 26, 2020</span>
                                            <h3>Winter Clothing Drive</h3>
                                            <p>We donated 100+ winter clothes to House of Mercy on December 26, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/dreamthings-org-house-of-mercy-december-05-2020.png" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> December 5, 2020</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated 20 Gallons of Juice, 8 cases of water bottles, and breakfast cereal boxes to House of Mercy on December 5, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/houseofmercy-11-05-2020.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> November 5, 2020</span>
                                            <h3>Winter Clothing and Water Donations</h3>
                                            <p>We donated 100 winter clothes and 10 cases of water to House of Mercy on Noveber 5, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/wecarecharity-10-31-2020.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> October 31, 2020</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated food items to We Care Charity on October 31, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/recent-story-4.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> October 4, 2020</span>
                                            <h3>Food and Item Donations</h3>
                                            <p>We donated non-perishable food items to We Care Charity’s food pantry along with other items on October 4, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/recent-story-3.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> September 27, 2020</span>
                                            <h3>Water and Item Donations</h3>
                                            <p>We donated 30 cases of water and undergarments to House of Mercy on September 27, 2020!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/recent-story-1.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> September 9, 2020</span>
                                            <h3>Water Donations</h3>
                                            <p>We recently had 30 cases of water donated to House of Mercy in August 2020! Due to the current pandemic and the hot weather, House of Mercy had run short of some essential items for the homeless, most importantly water.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/recent-story-2.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            <span className="date"><i className="fa fa-clock-o"></i> April - September, 2020</span>
                                            <h3>Meal Bags</h3>
                                            <p>When the COVID-19 pandemic started, many Homeless shelters were shut down. One way we have been helping shelters out with food donations is by creating meal bags. We donated 250 meal bags every week from April to September 2020 to House of Mercy and We Care Charity!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RecentStories;