import React from 'react';
import { Link } from 'react-router-dom';
class FeedHungry extends React.Component {
    render() {
        return (
            <section className="events-section bg-grey bd-bottom padding" id="FeedTheHungry">
                <div className="container">
                    <div className="section-heading text-center mb-40">
                        <h2>Feed the Hungry</h2>
                        <span className="heading-border"></span>
                        <p>We are collecting food donations for homeless shelters and charities.</p>
                    </div>
                    <div id="event-carousel" className="events-wrap col-md-8">
                        <div className="events-item">
                            <div className="event-thumb">
                                <img src="assets/img/events-1.png" alt="events" />
                            </div>
                            <div className="event-details">
                                <h3>Donate Food Items for We Care Charity</h3>
                                <div className="event-info">
                                    <p><i className="ti-calendar"></i>Items can be dropped off anytime</p>
                                    <p><i className="ti-location-pin"></i>Lexington, MA</p>
                                </div>
                                <p>Donate canned food items as well as other food items to assist We Care Charity.</p>
                                <p>&nbsp;</p>
                                <Link to="/donatefood" className="default-btn">Donate</Link>
                            </div>
                        </div>                   
                    </div>
                    <div className="section-heading text-center mb-40">
                        <br />
                        <Link to="/feedhungry" className="default-btn">More Events</Link>
                    </div>
                   



                </div>
                
            </section>
        )
    }
}

export default FeedHungry;