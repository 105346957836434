import React from 'react';
import { Link } from 'react-router-dom';

const initialstate = {
    name: '',
    email: '',
    note: '',
    message: '',
    isSubmitting: false,
    isError: false
}
class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialstate;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if (event.target.id === 'name') {
            this.setState({ name: event.target.value });
        } else if (event.target.id === 'email') {
            this.setState({ email: event.target.value });
        } else if (event.target.id === 'note') {
            this.setState({ note: event.target.value });
        }

    }

    handleSubmit = async e => {
        e.preventDefault();
        console.log(this.state);
        this.setState({ isSubmitting: true });
        console.log(JSON.stringify(this.state));
        var jsondata = JSON.stringify(this.state);
        console.log("json data->" + JSON.stringify(this.state));
        const res = await fetch("https://api.dreamthings.org/v1/contact", {
            method: "POST",
            body: jsondata,
            headers: {
                "Content-Type": "application/json"
            }
        });
        this.setState({ isSubmitting: false });
        const data = await res.json();
        !data.hasOwnProperty("error")
            ? this.setState({ message: 'Thank you for contacting us!' })
            : this.setState({ message: 'Error processing form', isError: true });

        setTimeout(
            () =>
                this.setState({
                    isError: false,
                    message: "",
                    name: "",
                    email: "",
                    note: ""
                }),
            1600
        );
    };

    render() {
        return (
            <div>                
                <div className="pager-header">
                    <div className="container">                        
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Contact</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Contact</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <Link to="/donate">Donate</Link>                                        
                                    </ol>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <section className="contact-section padding">                
                    <div className="container">
                        <div className="row contact-wrap">
                            <div className="col-md-6 xs-padding">
                                <div className="contact-info">
                                    <h3>Get in touch</h3>
                                    <ul>
                                        <li><i className="ti-location-pin"></i>Lexington, MA 02421</li>
                                        <li><i className="ti-email"></i>info@DreamThings.org</li>
                                        {/* <li><i className="ti-mobile"></i></li> */}
                                    </ul>
                                    <iframe title="Dreamthings"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47104.65960224375!2d-71.26010805475548!3d42.44813895464989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39ddadf5258cb%3A0x853685c8163613cd!2sLexington%2C%20MA!5e0!3m2!1sen!2sus!4v1600266303441!5m2!1sen!2sus"
                     width="300" height="190" style={{border:0}} ></iframe>
                                </div>
                            </div>
                            <div className="col-md-6 xs-padding">
                                <div className="contact-form">
                                    <h3>Drop us a message</h3>
                                    <p>Drop us a message for any questions, concerns, or feedback!</p>
                                    <form onSubmit={this.handleSubmit} id="ajax_form" className="form-horizontal">
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-6">
                                                <input type="text" id="name" value={this.state.name} onChange={this.handleChange} className="form-control" placeholder="Name" required />
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="email" id="email" value={this.state.email} onChange={this.handleChange} className="form-control" placeholder="Email" required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <textarea id="note" value={this.state.note} onChange={this.handleChange} cols="30" rows="5" className="form-control message" placeholder="Message" required></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <button id="submit" className="default-btn" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                        <div className={`message ${this.state.isError && "error"}`}>
                                            {this.state.isSubmitting ? "Submitting..." : this.state.message}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                
               
            </div>
        )
    }
}



export default Contact;