import React from 'react';
import { Link } from 'react-router-dom';

class NavEmbeded extends React.Component {
    render() {
        return (
            <div className="causes-wrap row center">
                {/* <div className="col-md-2 xs-padding"></div> */}
                <div className="col-md-2 xs-padding">
                    <Link to="/howitworks" className="default-btn">How It Works</Link>
                    {/* <a href="/howitworks" className="default-btn">How It Works</a> */}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2 xs-padding">
                    <Link to="/about" className="default-btn">About us</Link>
                    {/* <a href="/howitworks" className="default-btn">How It Works</a> */}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2 xs-padding">
                    <Link to="/contact" className="default-btn">Contact</Link>
                    {/* <a href="/howitworks" className="default-btn">How It Works</a> */}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2 xs-padding">
                    <Link to="/recentstories" className="default-btn">Recent Stories</Link>
                    {/* <a href="/recentstories" className="default-btn">Recent Stories</a> */}
                </div>
            </div>
        )
    }
}

export default NavEmbeded;
