import React from 'react';
// import { Link } from 'react-router-dom';
import Counter from '../components/Counter';
import Promo from '../components/Promo';
// import Reviews from '../components/Reviews';
// import Slider from '../components/Slider';
import NavEmbeded from '../components/NavEmbeded';
import Mission from '../components/Mission';
import ShowcaseStories from '../components/ShowcaseStories';
import PersonalMessage from '../components/PersonalMessage';
import FeedHungry from '../components/FeedHungry';
import MakeADonation from '../components/MakeADonation';
class Home extends React.Component {

    render() {
        return (
            <div>

                {/* <Slider /> */}

                <div className="header-height"></div>
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content text-center">
                            <div className="causes-wrap row center">
                                <div className="col-md-12 xs-padding">
                                    <p></p>                                   
                                    <h2>Better Life for People</h2>
                                </div>
                            </div>
                            <NavEmbeded />
                            
                        </div>
                    </div>
                </div>

                <Promo />

                <MakeADonation />

                <Mission />

                <FeedHungry />

                <Counter />

                

                {/* <Reviews /> */}

                {/* <PersonalMessage /> */}

                <ShowcaseStories />

            </div>
        )
    }
}

export default Home;