import React from 'react';
import { Link } from 'react-router-dom';

class DonateOther extends React.Component {

    render() {
        return (
            <div>                
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>Donate</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">Donate</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="promo-section bd-bottom">
                    <div className="promo-wrap">
                    <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-1.png" alt="prmo icon"/> */}
                                        <h3>Donate via GiveLively</h3>
                                        <p>Donate through a GiveLively page</p>
                                        {/* <Link to="/donate" className="default-btn">Donate</Link> */}
                                        <a href="https://hack.ms/dreamthings" target="_blank" rel="noopener noreferrer" className="default-btn">Donate Now</a>
                                    </div>  
                                </div>
                                <div className="col-md-4 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-2.png" alt="prmo icon"/> */}
                                        <h3>Donate by Bank Transfer</h3>
                                        <p>Donate by check or bank transfer</p>
                                        <Link to="/donatebycheck" className="default-btn">Donate Now</Link>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 xs-padding">
                                    <div className="promo-content">
                                        {/* <img src="assets/img/icon-3.png" alt="prmo icon"/> */}
                                        <h3>Donate Items</h3>
                                        <p>Fill out a form to donate items</p>
                                        <Link to="/donateitems" className="default-btn">Donate Items</Link>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </section>                
            </div>
        )
    }
}

export default DonateOther;