import React from 'react';
import {Link} from 'react-router-dom';
import Promo from '../components/Promo';

class Howitworks extends React.Component{
    render() {
        return(
            <div>                
                <div className="pager-header">
                    <div className="container">
                        <div className="page-content">
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <h2>How DreamThings Works and Our Mission</h2>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item active">How it Works</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="causes-wrap row">                                                                
                                <div className="col-md-12 xs-padding">
                                    <ol className="breadcrumb">
                                        <Link to="/donate">Donate</Link>                                        
                                    </ol>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="about-section bg-grey bd-bottom padding">
                    <div className="container">
                        <div className="row about-wrap">
                            <div className="col-md-3 xs-padding">                                
                            </div>
                            <div className="col-md-6 xs-padding">
                                <div className="about-content">                                    
                                    <p>DreamThings is a non-profit organization dedicated to supporting charity organizations and underprivileged people around the Boston area. We collect monetary, item, and food donations needed by various organizations and people. From the monetary funds collected, we purchase the items and deliver them to the designated organizations; all donations are tax deductible. The item and food donations are also delivered to the designated organizations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Promo/>
                                
            </div>
        )
    }
}
export default Howitworks;