import React from 'react';
import { Link } from 'react-router-dom';
class PersonalMessage extends React.Component {
    render() {
        return (
            <section className="blog-section bd-bottom padding" id="PersonalizedMessages">
                <div className="container">
                    <div className="section-heading text-center mb-40">
                        <h2>Personalized Messages</h2>
                        <span className="heading-border"></span>
                        <p>We are creating small cards with words of kindness and sending them to the homeless at House of Mercy. If you would like, you can send in your own message and we will put it on a card!</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 xs-padding">
                            <div className="blog-items grid-list row">
                                <div className="col-md-2 padding-15"></div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/mission-1.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            {/* <span className="date"><i className="fa fa-clock-o"></i> September 9, 2020</span> */}
                                            {/* <h3><a href="#">Water Donations</a></h3> */}
                                            {/* <p>We recently had 30 cases of water donated to House of Mercy in August 2020! Due to the current pandemic and the hot weather, House of Mercy had run short of some essential items for the homeless, most importantly water.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 padding-15">
                                    <div className="blog-post">
                                        <img src="assets/img/mission-2.jpg" alt="blog post" />
                                        <div className="blog-content">
                                            {/* <span className="date"><i className="fa fa-clock-o"></i> September 9, 2020</span> */}
                                            {/* <h3><a href="#">Meal Bags</a></h3> */}
                                            {/* <p>When the COVID-19 pandemic started, many Homeless shelters were shut down. One way we have been helping out shelters with food donations is by creating meal bags. We donated 250 meal bags every week from April to September 2020 to House of Mercy and We Care Charity!</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-heading text-center mb-40">
                                <Link to="/stickers" className="default-btn">Write a message</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        )
    }
}

export default PersonalMessage;